import axios from '../axios';
import config from '../../config';

const {BASE_URL} = config;

export async function getExportsColumns() {
  try {
    const response = await axios.get(`${BASE_URL}/reports/get-exports-columns`);
    return response.data;
  } catch (err) {
    return err.response.data;
  }
}

export async function exportsReport(columns) {
  try {
    const response = await axios.get(`${BASE_URL}/reports/exports`, columns);
    return response.data;
  } catch (err) {
    return err.response.data;
  }
}

export async function bestSellers(params) {
  const response = await axios.get(`${BASE_URL}/reports/best-sellers`, params);
  return response;
}
export async function countryWise(params) {
  const response = await axios.get(`${BASE_URL}/reports/country-wise`, params);
  return response;
}
export async function provinceWise(params) {
  const response = await axios.get(`${BASE_URL}/reports/province-wise`, params);
  return response;
}
export async function cityWise(params) {
  const response = await axios.get(`${BASE_URL}/reports/city-wise`, params);
  return response;
}
export async function pincodeWise(params) {
  const response = await axios.get(`${BASE_URL}/reports/pincode-wise`, params);
  return response;
}
export async function grossRevenue(params) {
  const response = await axios.get(`${BASE_URL}/reports/gross-revenue`, params);
  return response;
}
export async function netRevenue(params) {
  const response = await axios.get(`${BASE_URL}/reports/net-revenue`, params);
  return response;
}
export async function aov(params) {
  const response = await axios.get(`${BASE_URL}/reports/avg-order-value`, params);
  return response;
}
export async function itemsPerOrder(params) {
  const response = await axios.get(`${BASE_URL}/reports/avg-items-per-order`, params);
  return response;
}
export async function returnRevenue(params) {
  const response = await axios.get(`${BASE_URL}/reports/refund-revenue`, params);
  return response;
}
export async function costOfGoodsSold(params) {
  const response = await axios.get(`${BASE_URL}/reports/cost-of-goods-sold`, params);
  return response;
}
export async function firstOrderValueOfRepeatCustomers(params) {
  const response = await axios.get(`${BASE_URL}/reports/first-order-value`, params);
  return response;
}
export async function discountCodeUsage(params) {
  const response = await axios.get(`${BASE_URL}/reports/discount-code-usage`, params);
  return response;
}
export async function discounts(params) {
  const response = await axios.get(`${BASE_URL}/reports/discounts`, params);
  return response;
}
export async function lifetimeValue(params) {
  const response = await axios.get(`${BASE_URL}/reports/lifetime-value`, params);
  return response;
}
export async function repeatCustomers(params) {
  const response = await axios.get(`${BASE_URL}/reports/repeat-customers`, params);
  return response;
}
export async function getCohortReport(params) {
  const response = await axios.get(`${BASE_URL}/reports/get-report`, params);
  return response;
}
export async function getCompareCohortReport(body) {
  const response = await axios.post(`${BASE_URL}/reports/compare-cohort-report`, body);
  return response;
}
export async function revenueBySource(params) {
  const response = await axios.get(`${BASE_URL}/reports/revenue-by-source`, params);
  return response;
}
export async function rfmAnalysis(params) {
  const response = await axios.get(`${BASE_URL}/reports/rfm-analysis`, params);
  return response;
}
export async function rfmCategoryAnalysis(params) {
  let param = {params};
  const response = await axios.get(`${BASE_URL}/reports/rfm-category-analysis`, param);
  return response;
}
export async function abandonedCheckouts(params) {
  const response = await axios.get(`${BASE_URL}/reports/abandoned-checkouts`, params);
  return response;
}

export async function topCouponCodes(params) {
  const response = await axios.get(`${BASE_URL}/reports/top-coupon-codes`, params);
  return response;
}
export async function topItemsSold(params) {
  const response = await axios.get(`${BASE_URL}/reports/top-items-sold`, params);
  return response;
}

export async function ltvTable() {
  const response = await axios.get(`${BASE_URL}/reports/ltv-table`);
  return response;
}
export async function mostAbandonedProductsTable(params) {
  const response = await axios.get(`${BASE_URL}/reports/most-abandoned-products-table`, params);
  return response;
}
export async function fetchDashboard() {
  const response = await axios.get(`${BASE_URL}/reports/fetch-dashboard`);
  return response.data;
}
export async function updateJobStatus(params) {
  const response = await axios.get(`${BASE_URL}/reports/update-job`, params);
  return response.data;
}

export async function productNps(params) {
  const response = await axios.get(`${BASE_URL}/reports/nps-product`, params);
  return response;
}

export async function marketingDashboard(params) {
  const response = await axios.get(`${BASE_URL}/reports/marketing-dashboard`, params);
  return response;
}

export async function dailySummary(params) {
  const response = await axios.get(`${BASE_URL}/reports/daily-summary`, params);
  return response;
}
export async function profitAndLossStatement(params) {
  const response = await axios.get(`${BASE_URL}/reports/pnl-statement`, params);
  return response;
}
export async function productsOutOfStock(params) {
  const response = await axios.get(`${BASE_URL}/reports/products-out-of-stock`, params);
  return response;
}
export async function productsAtRisk(params) {
  const response = await axios.get(`${BASE_URL}/reports/products-at-risk`, params);
  return response;
}
export async function rfmRepeat(params) {
  const response = await axios.get(`${BASE_URL}/reports/rfm-repeat`, params);
  return response;
}
export async function rfmAtrisk(params) {
  const response = await axios.get(`${BASE_URL}/reports/rfm-at-risk`, params);
  return response;
}
export async function droppedSales(params) {
  const response = await axios.get(`${BASE_URL}/reports/dropped-sales`, params);
  return response;
}
export async function cartRecovery(params) {
  const response = await axios.get(`${BASE_URL}/reports/cart-recovery`, params);
  return response;
}
export async function marketingSyncProgress() {
  const response = await axios.get(`${BASE_URL}/reports/marketing-sync-progress`);
  return response;
}
