import React from 'react';
import {Tooltip, SkeletonBodyText} from '@shopify/polaris';
import {formatAmount, formatDate, formatNumber} from '../../../features/format';
import {infoCols} from '../columns';
import Stack from '../../../lib/Stack';
import Inline from '../../../lib/Inline';
import Card from '../../../lib/Card';
import Box from '../../../lib/Box';
import Icon from '../../../lib/Icon';
import Text from '../../../lib/Text';
let date = new Date();

/**
 * Generate a string which shows date range eg: 1 Feb 2022 - 31 Mar 2022
 * @param {Date Object} date current date object
 * @param {Number} months, months to subtract
 * @returns {String} date1 - date2, as in eg shown above
 */
const generateDateRangeString = (date, months) => {
  let sDate = new Date(date);
  let eDate = new Date(sDate);
  sDate.setDate(1);
  sDate.setMonth(sDate.getMonth() - months);
  eDate.setMonth(eDate.getMonth() - months);
  return `${formatDate({
    value: sDate,
    removeTime: true,
  })} - ${formatDate({
    value: eDate,
    removeTime: true,
  })}`;
};

const channelToTextMap = {
  googleAds: 'Google ads spent',
  facebookAds: 'Facebook ads spent',
};

const months = [0, 1, 3, 6, 12, 24]; //months to subtract
const Indicator = ({data, revenue, symbol, state, isMarketingDashboard}) => {
  let generateContent = key => {
    return isMarketingDashboard
      ? formatNumber({value: data[0][key].blendedRoas})
      : symbol
      ? formatAmount({value: data[0][key]})
      : formatNumber({value: data[0][key]});
  };
  let generateTooltipContent = key => {
    return isMarketingDashboard ? (
      <>
        {Object.entries(data[0][key]).map(([k, v], index) => {
          if (k === 'channels') {
            return (
              <>
                {v.map((item, index) => {
                  return (
                    <Card.Section key={`${k}-${index}}`}>
                      <Text>
                        {channelToTextMap[item.channel]} : {formatAmount({value: item.spend})}
                      </Text>
                    </Card.Section>
                  );
                })}
              </>
            );
          } else if (k === 'totalRevenue') {
            return (
              <Card.Section key={index}>
                <Text>Total revenue: {formatAmount({value: v})}</Text>
              </Card.Section>
            );
          }
        })}
      </>
    ) : symbol ? (
      data[0][key]
    ) : (
      data[0][key]
    );
  };
  return (
    <>
      <Card>
        <Card.Section>
          <Inline align="center">
            <Box width="90%">
              {state === 'Error' ? (
                <>
                  <Text>Not able to load data. Please try again later</Text>
                </>
              ) : state === 'Loading' ? (
                <SkeletonBodyText />
              ) : (
                <Inline align="space-between" blockAlign="start">
                  {data.length
                    ? Object.keys(data[0]).map((key, i) => {
                        return (
                          <Box key={i}>
                            <Stack vertical alignment="center">
                              <Tooltip
                                content={
                                  <Box padding={'2'} width="200px">
                                    {generateDateRangeString(date, months[i])}
                                  </Box>
                                }
                                dismissOnMouseOut
                                preferredPosition="above"
                              >
                                <Text color="subdued">{infoCols[key] ? infoCols[key] : key}</Text>
                              </Tooltip>
                              <Tooltip
                                content={generateTooltipContent(key)}
                                dismissOnMouseOut
                                preferredPosition="below"
                              >
                                <Text variant="headingXl">{generateContent(key)}</Text>
                              </Tooltip>
                              {i !== 0 ? (
                                <Box
                                  background={'surface-search-field'}
                                  borderRadius={'1'}
                                  padding={'1'}
                                  paddingInlineEnd="2"
                                >
                                  <Stack spacing="extraTight" alignment="center">
                                    <Icon color={revenue[i].color} source={revenue[i].source} />
                                    <Text variant="bodySm" color={revenue[i].color}>
                                      {revenue[i].value}
                                    </Text>
                                  </Stack>
                                </Box>
                              ) : (
                                ''
                              )}
                            </Stack>
                          </Box>
                        );
                      })
                    : ''}
                </Inline>
              )}
            </Box>
          </Inline>
        </Card.Section>
      </Card>
    </>
  );
};

export default Indicator;
