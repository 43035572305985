import React from 'react';
import Card from '../../../lib/Card';
import Stack from '../../../lib/Stack';
import {ProgressBar} from '@shopify/polaris';
import Text from '../../../lib/Text';

const typeMap = {
  googleAds: {
    heading: 'Google Ads',
  },
  facebookAds: {
    heading: 'Facebook Ads',
  },
};

const ProgressCard = ({data}) => {
  let dataEntries = Object.entries(data);
  const isAllCompleted = dataEntries.every(([key, value]) => {
    let totalCount = Object.values(value).reduce((a, b) => a + b, 0);
    let completedCount = value['completed'] || 0;
    return totalCount === completedCount;
  });
  return (
    <>
      {dataEntries.length > 0 && !isAllCompleted && (
        <Card title="Data sync progress" sectioned>
          {dataEntries.map(([key, value], index) => {
            let totalCount = Object.values(value).reduce((a, b) => a + b, 0);
            let completedCount = value['completed'] || 0;
            let percentage = ((completedCount * 100) / totalCount)?.toFixed(2) || 0;
            return (
              <Card.Section key={index}>
                <Stack vertical>
                  <Text variant="" fontWeight="bold">
                    {typeMap[key].heading}
                  </Text>

                  <Stack vertical="true">
                    <div
                      style={{
                        flex: '0.90',
                        display: 'flex',
                        alignItems: 'center',
                        gap: '2rem',
                      }}
                    >
                      <ProgressBar
                        color="success"
                        progress={(completedCount * 100) / totalCount}
                        size="small"
                      />
                      <Text variant="headingSm">{percentage}%</Text>
                    </div>
                  </Stack>
                </Stack>
              </Card.Section>
            );
          })}
        </Card>
      )}
    </>
  );
};

export default ProgressCard;
