import {Box, Button, Card, Loading} from '@shopify/polaris';
import {ErrorBoundary} from '../../../components/ErrorBoundary';
import DefaultIndexTable from '../../../components/defaultIndexTable';
import Inline from '../../../lib/Inline';
import {useEffect, useState, useCallback} from 'react';
import MultiSelect from '../../../components/multiSelect';
import apiToCall from '../../../features/apiToCall';
import {useSnapshotStore} from '../../../app/store';
import DateRangeComponent from '../../../components/dateRangeComponent';
import {
  convertToOrgTimezone,
  dateToNormalString,
  previousPeriod,
  previousYear,
  toClickHouseDate,
  toShortDate,
} from '../../../features/convertDates';
import Page from '../../../components/page';
import {useSearchParams} from 'react-router-dom';
import config from '../../../config';
import useApiCall from '../../../hooks/useApiCall';
import {getConnectionTemplate} from '../../../api/connection';
import ColdStart from '../../coldStart';
import {marketingSyncProgress} from '../../../api/reports';
import ProgressCard from './ProgressCard';
import DateCompareComponent from '../../../components/dateCompareComponent';
import DefaultLineGraph from '../../../components/graphs/defaultLineGraph';
import {LineChart} from '@shopify/polaris-viz';
import Indicator from '../../metrics/reportPage/indicator';
import {getRevenueValues} from '../../metrics/reportPage/revenueValues';

const dateRangeValueToLable = {
  today: 'Today',
  yesterday: 'Yesterday',
  lastSevenDays: 'Last 7 Days',
  lastThirtyDays: 'Last 30 Days',
  lastNinetyDays: 'Last 90 Days',
  lastOneYear: 'Last 1 Year',
};
const dateCompareObj = {
  previousPeriod: previousPeriod,
  previousYear: previousYear,
  noComparison: () => {
    return ['', ''];
  },
};
const dateCompareValueToLable = {
  previousPeriod: 'Previous Period',
  previousYear: 'Previous Year',
  noComparison: 'No Comparison',
};
const insightLevelValueToLable = {
  ad: 'Ad',
  adSet: 'Ad set',
  campaign: 'Campaign',
};

const MarketingDashboard = () => {
  const [progressData, setProgressData] = useState({});
  const {
    selectedDateRange,
    setSelectedDateRange,
    selectedDateRangeDates,
    setSelectedDateRangeDates,
    setSelectedCompareDates,
    selectedCompareDates,
    setSelectedCompare,
    selectedCompare,
  } = useSnapshotStore(state => state);
  const [searchParams, setSearchParams] = useSearchParams();
  const [marketingConnectionStatus, setMarketingConnectionStatus] = useState('loading');
  const [channel, setChannel] = useState('');
  const param = {name: 'marketing-dashboard'};
  const [page, setPage] = useState(searchParams.get('page') || 1);
  const [levelSelected, setLevelSelected] = useState(
    searchParams.get('insightLevel') || 'campaign'
  );
  const [dateRangePopoverActive, setDateRangePopoverActive] = useState(false);
  const [initRender, setInitRender] = useState(true);
  const [sortOrder, setSortOrder] = useState(
    searchParams.get('sortOrder') || apiToCall[param.name].sortOrder
  );
  const [queryValue, setQueryValue] = useState('');
  const [sortBy, setSortBy] = useState(searchParams.get('sortBy') || apiToCall[param.name].sortBy);
  const [popoverActive, setPopoverActive] = useState(false);

  const [durationFilter, setDurationFilter] = useState(
    searchParams.get('durationFilter') || 'total'
  );
  const dateRangeTogglePopoverActive = useCallback(
    () => setDateRangePopoverActive(dateRangePopoverActive => !dateRangePopoverActive),
    []
  );
  const [revenue, setRevenue] = useState([]);

  const getDateRangeDates = (dates, selectedDateRange) => {
    setSelectedDateRangeDates(dates);
    setSelectedDateRange(selectedDateRange);
  };
  const getDates = (dates, selectedCompar) => {
    setSelectedCompareDates(dates);
    setSelectedCompare(selectedCompar[0]);
  };

  useEffect(() => {
    // compare Dates auto change on selected date changed
    if (selectedCompare) {
      let [sdate, edate] = dateCompareObj[selectedCompare](selectedDateRangeDates);
      setSelectedCompareDates({start: sdate, end: edate});
    }
  }, [selectedDateRangeDates]);
  useEffect(() => {
    let isApiCalled = false;
    setTimeout(() => {
      if (!isApiCalled) {
        if (!initRender) {
          let params = {};

          params = {
            startDate: toShortDate(selectedDateRangeDates.start),
            endDate: toShortDate(selectedDateRangeDates.end),
            page,
            sortBy,
            sortOrder,
            insightLevel: levelSelected,
            durationFilter,
          };

          params = {...params};
          let flag = false;
          for (let key in params) {
            if (searchParams.get(key) !== params[key]) {
              flag = true;
            }
          }
          if (flag) {
            setSearchParams({...params});
          }
        } else {
          setInitRender(false);
        }
      }
    }, config.DEBOUNCING_DELAY);
    return () => {
      isApiCalled = true;
    };
  }, [selectedDateRangeDates, page, levelSelected, sortBy, sortOrder, durationFilter]);

  const fetchMarketingData = async () => {
    let startDate = convertToOrgTimezone(toClickHouseDate(selectedDateRangeDates.start, 'start'));
    let endDate = convertToOrgTimezone(toClickHouseDate(selectedDateRangeDates.end, 'end'));
    let params = {
      startDate,
      endDate,
      page,
      search: queryValue,
      sortBy,
      sortOrder,
      type: 'table',
      insightLevel: levelSelected,
      durationFilter,
      channel,
    };
    let response = await apiToCall[param.name].api({params});
    return response.data;
  };

  const marketingDashboardApiDependencies = [
    selectedDateRangeDates,
    page,
    queryValue,
    sortBy,
    sortOrder,
    levelSelected,
    durationFilter,
    channel,
  ];

  const fetchGraphData = async () => {
    let startDate = convertToOrgTimezone(toClickHouseDate(selectedDateRangeDates.start, 'start'));
    let endDate = convertToOrgTimezone(toClickHouseDate(selectedDateRangeDates.end, 'end'));

    let params = {
      startDate,
      endDate,
      type: 'graph',
      durationFilter,
    };
    if (selectedCompareDates.start !== 'Invalid Date') {
      let compareStartDate = convertToOrgTimezone(
        toClickHouseDate(selectedCompareDates.start, 'start')
      );
      let compareEndDate = convertToOrgTimezone(toClickHouseDate(selectedCompareDates.end, 'end'));
      params = {...params, compareStartDate, compareEndDate};
    }
    let response = await apiToCall[param.name].api({params});
    return response.data;
  };
  const graphDataDependencies = [selectedDateRangeDates, durationFilter];

  const fetchInfoData = async () => {
    let response = await apiToCall[param.name].api({params: {type: 'info'}});
    let data;
    if (response.data.status) {
      data = Object.entries(response.data.data[0]).reduce((acc, [key, value]) => {
        return {...acc, [key]: value.blendedRoas};
      }, {});
      let rvalues = getRevenueValues(data);
      setRevenue([...rvalues]);
    }
    return response.data;
    // return {data: [data], status: response.data.status};
  };

  const infoDataDependencies = [];

  const graphData = useApiCall(fetchGraphData, graphDataDependencies);
  const infoData = useApiCall(fetchInfoData, infoDataDependencies);

  const marketingData = useApiCall(fetchMarketingData, marketingDashboardApiDependencies);

  const dateRangeActivator = (
    <div style={{width: '100%'}}>
      <Button onClick={dateRangeTogglePopoverActive} disclosure>
        {selectedDateRange
          ? dateRangeValueToLable[selectedDateRange]
          : dateToNormalString(selectedDateRangeDates)}
      </Button>
    </div>
  );

  const connectionVerify = async () => {
    setMarketingConnectionStatus('loading');
    let res = await getConnectionTemplate({params: {type: 'connected'}});
    if (res.status) {
      for (let connection of res.data) {
        if (
          (connection.type === 'facebookAds' || connection.type === 'googleAds') &&
          connection.data
        ) {
          setMarketingConnectionStatus('connected');
          return;
        }
      }
      setMarketingConnectionStatus('not-connected');
      return;
    }
  };
  const fetchSyncProgress = async () => {
    let res = await marketingSyncProgress();
    if (res.data) {
      if (res.data.status) {
        setProgressData(res.data.data);
      }
    }
  };
  useEffect(() => {
    connectionVerify();
    fetchSyncProgress();
  }, []);
  const togglePopoverActive = useCallback(
    () => setPopoverActive(popoverActive => !popoverActive),
    []
  );
  const activator = (
    <div style={{width: '100%'}}>
      <Button onClick={togglePopoverActive} disclosure>
        Compare:{' '}
        {selectedCompare
          ? dateCompareValueToLable[selectedCompare]
          : dateToNormalString(selectedCompareDates)}
      </Button>
    </div>
  );

  return (
    <>
      {marketingConnectionStatus === 'not-connected' ? (
        <>
          <ColdStart page={'page10'} />
        </>
      ) : marketingConnectionStatus === 'loading' ? (
        <>
          <Loading />
        </>
      ) : (
        <Page title={apiToCall['marketing-dashboard'].title}>
          <Box paddingBlockEnd={'4'} paddingBlockStart={'4'}>
            <Inline>
              <DateRangeComponent
                currentDates={selectedDateRangeDates}
                activator={dateRangeActivator}
                togglePopoverActive={dateRangeTogglePopoverActive}
                popoverActive={dateRangePopoverActive}
                getDates={getDateRangeDates}
                currentDateOption={selectedDateRange}
              />
              <DateCompareComponent
                currentDates={selectedCompareDates}
                activator={activator}
                togglePopoverActive={togglePopoverActive}
                popoverActive={popoverActive}
                selectedCompare={selectedCompare}
                getDates={getDates}
                selectedDateRangeDates={selectedDateRangeDates}
              />
              <MultiSelect
                title="Level"
                options={[
                  {label: 'Ad', value: 'ad'},
                  {label: 'Ad set', value: 'adSet'},
                  {label: 'Campaign', value: 'campaign'},
                ]}
                selected={levelSelected}
                setSelected={setLevelSelected}
                disableMultiple={true}
                setPage={setPage}
                setChecked={() => {}}
              />
              <MultiSelect
                title="Group by"
                options={[
                  {label: 'Total', value: 'total'},
                  {label: 'Daily', value: 'daily'},
                  {label: 'Weekly', value: 'weekly'},
                  {label: 'Monthly', value: 'monthly'},
                ]}
                selected={durationFilter}
                setSelected={setDurationFilter}
                disableMultiple={true}
                setPage={setPage}
                setChecked={() => {}}
              />
              <MultiSelect
                title="Channel"
                options={[
                  {label: 'All', value: ''},
                  {label: 'Google Ads', value: 'googleAds'},
                  {label: 'Facebook Ads', value: 'facebookAds'},
                ]}
                selected={channel}
                setSelected={setChannel}
                disableMultiple={true}
                setPage={setPage}
                setChecked={() => {}}
              />
            </Inline>
          </Box>
          <Box paddingBlockEnd={'4'} paddingBlockStart={'4'}>
            <ProgressCard data={progressData} />
          </Box>
          <Box paddingBlockEnd={'4'}>
            <Card>
              <Card.Section>
                <ErrorBoundary fallback={<LineChart data={[]} state="Error" theme="Light" />}>
                  <DefaultLineGraph
                    data={graphData.data}
                    compareStartDate={toClickHouseDate(selectedCompareDates.start, 'start')}
                    compareEndDate={toClickHouseDate(selectedCompareDates.end, 'end')}
                    startDate={toClickHouseDate(selectedDateRangeDates.start, 'start')}
                    endDate={toClickHouseDate(selectedDateRangeDates.end, 'end')}
                    param={{name: 'marketing-dashboard'}}
                    durationFilter={durationFilter}
                    state={graphData.state}
                  />
                </ErrorBoundary>
              </Card.Section>
            </Card>
          </Box>
          <Box paddingBlockEnd={'4'}>
            <ErrorBoundary
              fallback={
                <Card>
                  <Card.Section>
                    <div>An error has occured</div>
                  </Card.Section>
                </Card>
              }
            >
              <Indicator
                symbol={apiToCall['customers'].symbol}
                data={infoData.data}
                state={infoData.state}
                revenue={revenue}
                isMarketingDashboard
              />
            </ErrorBoundary>
          </Box>
          <Box paddingBlockEnd={'4'}>
            <ErrorBoundary
              fallback={
                <Card>
                  <Card.Section>
                    <div>An error has occured</div>
                  </Card.Section>
                </Card>
              }
            >
              <DefaultIndexTable
                select={false}
                page={page}
                setPage={setPage}
                resourceName={{
                  singular: 'metrics',
                  plural: 'metrics',
                }}
                columns={apiToCall['marketing-dashboard'].columns
                  .filter(column => {
                    if (durationFilter === 'total' && column.value === 'date') {
                      return false;
                    }
                    return true;
                  })
                  .map(column =>
                    column.value === 'insightLevelName'
                      ? {
                          ...column,
                          title: column.title.replace(
                            '{insightLevel}',
                            insightLevelValueToLable[levelSelected]
                          ),
                        }
                      : column
                  )}
                selectedFilters={[]}
                totalRows={marketingData.data.length > 0 ? marketingData.data[0].totalRows : 0}
                tableData={marketingData.data}
                setSelectedDateRangeDates={setSelectedDateRangeDates}
                state={marketingData.state}
                param={param}
                disableSort={false}
                isSearchEnabled={true}
                sortBy={sortBy}
                sortOrder={sortOrder}
                setSortBy={setSortBy}
                setSortOrder={setSortOrder}
                queryValue={queryValue}
                setQueryValue={setQueryValue}
                durationFilter={durationFilter}
              />
            </ErrorBoundary>
          </Box>
        </Page>
      )}
    </>
  );
};

export default MarketingDashboard;
