import {Box, Card, Spinner} from '@shopify/polaris';
import Inline from '../../../lib/Inline';
import {toClickHouseDate, toShortDate, convertToOrgTimezone} from '../../../features/convertDates';
import React from 'react';
import {useEffect} from 'react';
import {useState} from 'react';
import Page from '../../../components/page';
// import DefaultTable from '../../../components/defaultIndexTable';
import {useSearchParams} from 'react-router-dom';
import apiToCall from '../../../features/apiToCall';
import config from '../../../config';
import {ErrorBoundary} from '../../../components/ErrorBoundary';
import {track} from '../../../features/track';
import Alert from '../../../components/alert';
import useApiCall from '../../../hooks/useApiCall';
import DownloadModal from '../../../components/Modals/downloadModal';
import VerticalIndexTable from '../../../components/verticalIndexTable';
import YearSelectComponent from '../../../components/yearSelect';
import YearlyQuarterSelectComponent from '../../../components/quarterSelectComponent';
import {LineChart} from '@shopify/polaris-viz';
import DefaultLineGraph from '../../../components/graphs/defaultLineGraph';
import {LocalStorageService} from '../../../service/localStorage';

const ProfitAndLoss = () => {
  const param = {name: 'p&l'};
  const [searchParams, setSearchParams] = useSearchParams();
  const [sortOrder, setSortOrder] = useState(apiToCall[param.name].sortOrder);
  const [queryValue, setQueryValue] = useState('');
  const [page, setPage] = useState(1);
  const [sortBy, setSortBy] = useState(apiToCall[param.name].sortBy);
  const [showAlert, setShowAlert] = useState('');
  const currentYear = new Date().getFullYear();
  const userDetails = LocalStorageService.getUserOrg();
  const userCurrency = userDetails.currency;

  const [durationFilter, setDurationFilter] = useState(currentYear.toString());

  const [selectedDateRangeDates, setSelectedDateRangeDates] = useState({
    start: new Date(),
    end: new Date(),
  });

  const handleDateRangeSelect = ({startDate, endDate}) => {
    setSelectedDateRangeDates({
      start: new Date(startDate),
      end: new Date(endDate),
    });
  };

  const [download, setDownload] = useState({state: 'Success'});
  const [initRender, setInitRender] = useState(true);

  const [openDownloadModal, setOpenDownloadModal] = useState(false);

  useEffect(() => {
    track('Page View', {
      name: 'P&L',
    });
  }, []);

  useEffect(() => {
    let isApiCalled = false;
    setTimeout(() => {
      if (!isApiCalled) {
        if (!initRender) {
          let params = {};

          params = {
            startDate: toShortDate(selectedDateRangeDates.start),
            endDate: toShortDate(selectedDateRangeDates.end),
          };

          params = {...params};
          let flag = false;
          for (let key in params) {
            if (searchParams.get(key) !== params[key]) {
              flag = true;
            }
          }
          if (flag) {
            setSearchParams({...params});
          }
        } else {
          setInitRender(false);
        }
      }
    }, config.DEBOUNCING_DELAY);
    return () => {
      isApiCalled = true;
    };
  }, [selectedDateRangeDates]);

  const fetchOrdersData = async () => {
    let startDate = convertToOrgTimezone(toClickHouseDate(selectedDateRangeDates.start, 'start'));
    let endDate = convertToOrgTimezone(toClickHouseDate(selectedDateRangeDates.end, 'end'));
    let params = {
      startDate,
      endDate,
      page,
      search: queryValue,
      sortBy,
      sortOrder,
      type: 'table',
      currency: userCurrency,
    };
    let response = await apiToCall[param.name].api({params});
    return response.data;
  };

  const ordersApiDependencies = [selectedDateRangeDates, page, queryValue, sortBy, sortOrder];

  const ordersData = useApiCall(fetchOrdersData, ordersApiDependencies);

  const fetchGraphData = async () => {
    let startDate = convertToOrgTimezone(toClickHouseDate(selectedDateRangeDates.start, 'start'));
    let endDate = convertToOrgTimezone(toClickHouseDate(selectedDateRangeDates.end, 'end'));
    let params = {
      startDate,
      endDate,
      page,
      search: queryValue,
      sortBy,
      sortOrder,
      type: 'graph',
      currency: userCurrency,
    };
    let response = await apiToCall[param.name].api({params});
    return response.data;
  };

  const graphApiDependencies = [selectedDateRangeDates, page, queryValue, sortBy, sortOrder];

  const graphData = useApiCall(fetchGraphData, graphApiDependencies);

  const handleDownload = async email => {
    setShowAlert('');
    let startDate = convertToOrgTimezone(toClickHouseDate(selectedDateRangeDates.start, 'start'));
    let endDate = convertToOrgTimezone(toClickHouseDate(selectedDateRangeDates.end, 'end'));
    const params = {
      type: 'download',
      startDate,
      endDate,
      search: queryValue,
      sortBy,
      sortOrder,
      page,
      email,
      currency: userCurrency,
    };
    setDownload({state: 'Loading'});
    try {
      let response = await apiToCall[param.name].api({params});
      setShowAlert(`P&L statement will be mailed to you shortly on ${email}`);
      setDownload({state: 'Success'});
    } catch (e) {
      setDownload({state: 'Error'});
    }
  };
  return (
    <>
      <Page
        secondaryActions={[
          {
            content: download.state === 'Loading' ? <Spinner size="small" /> : 'Download',
            destructive: false,
            onAction: () => {
              setOpenDownloadModal(true);
            },
          },
        ]}
        title={apiToCall[param.name].title}
      >
        <Box paddingBlockEnd={'4'}>
          <Inline>
            <YearSelectComponent
              setDurationFilter={setDurationFilter}
              durationFilter={durationFilter}
              setPage={setPage}
              setQueryValue={setQueryValue}
            />
            <YearlyQuarterSelectComponent
              durationFilter={durationFilter}
              onSelect={handleDateRangeSelect}
            />
          </Inline>
        </Box>

        <Box paddingBlockEnd={'4'}>
          <Card>
            <Card.Section>
              <ErrorBoundary fallback={<LineChart data={[]} state="Error" theme="Light" />}>
                <DefaultLineGraph
                  data={graphData.data}
                  startDate={toClickHouseDate(selectedDateRangeDates.start, 'start')}
                  endDate={toClickHouseDate(selectedDateRangeDates.end, 'end')}
                  param={{name: 'p&l'}}
                  durationFilter={'monthly'}
                  state={graphData.state}
                  isDynamic={true}
                  symbol={true}
                />
              </ErrorBoundary>
            </Card.Section>
          </Card>
        </Box>

        <Box paddingBlockEnd={'4'}>
          <ErrorBoundary
            fallback={
              <Card>
                <Card.Section>
                  <div>An error has occured</div>
                </Card.Section>
              </Card>
            }
          >
            <VerticalIndexTable
              sortBy={sortBy}
              setSortBy={setSortBy}
              sortOrder={sortOrder}
              setSortOrder={setSortOrder}
              select={false}
              queryValue={queryValue}
              setQueryValue={setQueryValue}
              page={page}
              setPage={setPage}
              resourceName={{
                singular: 'metrics',
                plural: 'metrics',
              }}
              columns={apiToCall[param.name].columns}
              headings={apiToCall[param.name].headings}
              selectedFilters={[]}
              totalRows={ordersData.data.length > 0 ? ordersData.data[0].totalRows : 0}
              tableData={ordersData.data.length > 0 ? ordersData.data : []}
              state={ordersData.state}
              param={param}
              durationFilter={durationFilter}
            />
          </ErrorBoundary>
        </Box>
        {showAlert && (
          <Alert
            contents={showAlert}
            init={true}
            error={showAlert === 'Something Went Wrong'}
            duration={10000}
          />
        )}
      </Page>
      <DownloadModal
        isOpen={openDownloadModal}
        toggle={() => setOpenDownloadModal(!openDownloadModal)}
        handleDownload={handleDownload}
      />
    </>
  );
};

export {ProfitAndLoss};
