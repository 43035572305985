import React, {useCallback, useEffect, useState} from 'react';
import FormWizard from 'react-form-wizard-component';
import 'react-form-wizard-component/dist/style.css';
import NpsData from './NpsData';
import FacebookData from './FacebookData';
import EmailSummaryData from './EmailSummaryData';
import OptionalData from './OptionalData';
import {createConnection, getConnectionTemplate} from '../../../api/connection';
import {createOrUpdateOnboarding, getOnboarding} from '../../../api/onboarding';
import {EmptyState, Spinner} from '@shopify/polaris';
import Card from '../../../lib/Card';
import Text from '../../../lib/Text';
import {LocalStorageService} from '../../../service/localStorage';
import Alert from '../../../components/alert';

export default function OnboardingWizard() {
  const [initStep, setInitStep] = useState(0);
  const [completed, setCompleted] = useState(false);
  const [nps, setNps] = useState(null);
  const [twilio, setTwilio] = useState(null);
  const [facebook, setFacebook] = useState(null);
  const [sourceText, setSourceText] = useState('');
  const [showAlert, setShowAlert] = useState('');
  const [loading, setLoading] = useState(false);
  const [initLoading, setInitLoading] = useState(true);
  const [isTwilioConnected, setIsTwilioConnected] = useState(false);
  const [isFacebookConnected, setIsFacebookConnectionConnected] = useState(false);
  const [emailSummaryReports, setEmailSummaryReports] = useState({
    'abandoned-cart-value': true,
    'net-revenue': true,
    orders: true,
    'repeat-customers': true,
    customers: true,
  });

  const [validTabs, setValidTabs] = useState({
    nps: true,
    facebook: false,
    emailSummary: false,
    finish: false,
  });

  const validateTab = useCallback(tab => {
    setValidTabs(prev => ({...prev, [tab]: true}));
  }, []);

  const handleComplete = () => {
    updateOnboarding(true);
    LocalStorageService.setOnboarding(false);
    setCompleted(true);
  };
  useEffect(() => {
    if (nps !== null) {
      if (!nps || twilio !== null) {
        validateTab('facebook');
      }
    }
    if (facebook !== null && facebook !== undefined) {
      validateTab('emailSummary');
    }
    let isEmailSummaryReportsTabValid = false;
    for (let key in emailSummaryReports) {
      if (emailSummaryReports[key]) {
        isEmailSummaryReportsTabValid = true;
      }
    }
    if (isEmailSummaryReportsTabValid) {
      validateTab('finish');
    } else {
      setValidTabs(prev => ({...prev, finish: false}));
    }
  }, [nps, twilio, facebook, validateTab, emailSummaryReports]);

  const onConnect = async params => {
    let result = await createConnection(params);
    if (result.status === true) {
      setIsTwilioConnected(true);
      setShowAlert('Connection added successfully!');
      if (params.type === 'facebookAds') {
        // setConnectionData(result.data);
        // setOpenUpdateFacebookAdAccountsModal(true);
      }
    } else {
      setShowAlert(`${result?.message ? result.message + '. ' : ''} Please try again!`);
    }
    return result;
  };

  const getOnboardingData = async () => {
    setInitLoading(true);
    let params = {params: {type: 'connected'}};
    let res = await getConnectionTemplate(params);
    const response = await getOnboarding();
    if (response.status && response.data) {
      if (response.data.completed) {
        LocalStorageService.setOnboarding(false);
      }
      setCompleted(response.data.completed);
      setFacebook(response.data.facebook);
      setNps(response.data.nps);
      setTwilio(response.data.twilio);
      setSourceText(response.data.sourceText);
      setEmailSummaryReports(response.data.emailSummaryReports);
      setInitStep(response.data.currentStep);
    }
    if (res.status && res.data) {
      res.data.forEach(connection => {
        if (connection.data) {
          if (connection.type === 'twilio') {
            setIsTwilioConnected(true);
          }
          if (connection.type === 'facebookAds') {
            setIsFacebookConnectionConnected(true);
          }
        }
      });
    }
    setInitLoading(false);
  };

  useEffect(() => {
    getOnboardingData();
  }, []);

  const updateStep = ({nextIndex}) => {
    setInitStep(nextIndex);
  };
  const updateOnboarding = async completed => {
    setLoading(true);
    let res = await createOrUpdateOnboarding({
      nps,
      twilio,
      emailSummaryReports,
      sourceText,
      currentStep: completed ? 3 : initStep,
      completed,
      facebook,
    });
    if (res.status) {
      // setShowAlert('Onboarding step updated successfully!');
    }
    setLoading(false);
  };
  return (
    <>
      {initLoading ? (
        <> </>
      ) : completed ? (
        <Card style={{width: '100%'}}>
          <EmptyState heading={'All set to go!'} image={'images/completed.png'}>
            <Text alignment="justify" variant="bodyMd">
              <p style={{lineHeight: '1.5rem'}}>
                Once we have completed the data-sync you will get an email. It should take less than
                24 hours. Keep an eye out on your spam box too.
              </p>
              <p style={{lineHeight: '1.5rem'}}>
                Meanwhile, here's our{' '}
                <a target="_blank" rel="noreferrer" href="https://www.youtube.com/@retainwiseio">
                  Youtube channel
                </a>{' '}
                explaining all the videos. How to use Segments as first video or something like
                that.
              </p>
            </Text>
          </EmptyState>
        </Card>
      ) : (
        <FormWizard
          startIndex={initStep}
          color="rgba(40, 70, 235)"
          stepSize="xs"
          onComplete={handleComplete}
          onTabChange={updateStep}
          backButtonTemplate={prev => (
            <div
              className="wizard-footer-left"
              style={{
                backgroundColor: 'rgb(40, 70, 235)',
                borderColor: 'rgb(40, 70, 235)',
                borderRadius: '4px',
              }}
            >
              <button disabled={loading} className="wizard-btn" onClick={prev}>
                Prev
              </button>
            </div>
          )}
          nextButtonTemplate={next => (
            <div
              className="wizard-footer-right"
              style={{
                backgroundColor: 'rgb(40, 70, 235)',
                borderColor: 'rgb(40, 70, 235)',
                borderRadius: '4px',
              }}
            >
              <button
                disabled={loading}
                className="wizard-btn"
                onClick={() => {
                  updateOnboarding();
                  next();
                }}
              >
                Next
              </button>
            </div>
          )}
        >
          <FormWizard.TabContent isValid={validTabs.nps} title="NPS">
            {loading ? (
              <Spinner />
            ) : (
              <NpsData
                setShowAlert={setShowAlert}
                nps={nps}
                twilio={twilio}
                setNps={setNps}
                setTwilio={setTwilio}
                onConnect={onConnect}
                isTwilioConnected={isTwilioConnected}
              />
            )}
          </FormWizard.TabContent>
          <FormWizard.TabContent isValid={validTabs.facebook} title="Facebook">
            <FacebookData
              onConnect={onConnect}
              setShowAlert={setShowAlert}
              facebook={facebook}
              setFacebook={setFacebook}
              isFacebookConnected={isFacebookConnected}
            />
          </FormWizard.TabContent>
          {/* <FormWizard.TabContent isValid title="Google">
          <h3>Last Tab</h3>
          <p>Some content for the last tab</p>
        </FormWizard.TabContent> */}
          <FormWizard.TabContent isValid={validTabs.emailSummary} title="Email summary">
            <EmailSummaryData
              emailSummaryReports={emailSummaryReports}
              setEmailSummaryReports={setEmailSummaryReports}
            />
          </FormWizard.TabContent>
          <FormWizard.TabContent isValid={validTabs.finish} title="Optional">
            <OptionalData hearText={sourceText} setHearText={setSourceText} />
          </FormWizard.TabContent>
        </FormWizard>
      )}
      {showAlert && (
        <Alert
          contents={showAlert}
          init={true}
          error={showAlert === 'Something Went Wrong'}
          duration={10000}
        />
      )}
    </>
  );
}
